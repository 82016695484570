.connecting-audio {
  background-color: rgba(var(--color__black-rgb), .7);
  border-radius: var(--border__radius);
  color: var(--color__white);
  left: 50%;
  padding: var(--spacing__large);
  position: fixed;
  text-align: center;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 10000;

  h3 {
    margin: 0;
  }
}

:global(.audio-loading) {
  display: block;
  fill: var(--color__white);
  height: 76px;
  margin: 0 auto var(--spacing);
  width: 76px;

  :global(.has-image) & {
    fill: var(--color__white);
  }
}