:global(.swiper-container) {
  overflow: visible !important;
}

.swiper-list {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  margin-bottom: var(--spacing__xxlarge);

  :global(.swiper) {
    padding-bottom: var(--spacing__xlarge);
  }

  :global(.swiper-horizontal>.swiper-scrollbar) {
    left: var(--spacing__large);
    width: calc(100% - 32px);
  }
}

.swiper-list__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 var(--spacing__large);

  > :last-child {
    text-align: right;
  }
}

.swiper-list__prev,
.swiper-list__next {
  background-color: rgb(var(--color__black-rgb), .5);
  color: var(--color__white);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  &:disabled {
    display: none;
  }

  :global(.light-contrast) & {
    color: var(--color__white);
  }
}

.swiper-list__prev {
  left: var(--spacing__xxlarge);
}

.swiper-list__next {
  right: var(--spacing__xxlarge);
}