.clipboard {
  position: relative;
}

.clipboard__button {
  display: block;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &:after {
    background-color: var(--color__green--light);
    border-radius: var(--border__radius);
    color: var(--color__green);
    content: "Copied!";
    left: 50%;
    font-family: var(--font__family);
    font-size: 9px;
    line-height: initial;
    opacity: 0;
    padding: 6px 0;
    position: absolute;
    transition: all .2s ease-in-out;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    top: 50%;
    visibility: hidden;
    width: 42px;
  }

  &.copied:after {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    border: 0;
  }

  .embed & {
    height: 32px;
    line-height: 32px;
    width: 46px;
  }
}

.clipboard__input {
  overflow: hidden;
  padding-right: 48px;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: 400px) {
    height: 32px;
    font-size: var(--font__size-mini);
    padding-left: var(--spacing);
  }

  .embed & {
    height: 32px;
    font-size: var(--font__size-mini);
    padding-left: var(--spacing);
  }
}