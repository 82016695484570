@import "scss/mixins";

// Wrapper component

.full-screen__wrapper {
  background-color: rgba(var(--color__primary), .2);
  display: flex;
  color: var(--color__white);
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  transition: max-height .3s ease-in-out;
  width: 100%;

  @media (prefers-reduced-motion) {
    transition: none;
  }

  @media screen and (max-width: 739px) {
    &:global(.chat-visible) {
      max-height: 50%;
    }
  }

  @media screen and (max-height: 410px) {
    &:global(.chat-visible) {
      max-height: 100%;
    }
  }

  @media (display-mode: standalone) {
    padding-top: env(safe-area-inset-top, 0);
    padding-bottom: calc(var(--spacing) + env(safe-area-inset-bottom, 0));
    padding-left: calc(var(--spacing) + env(safe-area-inset-left, 0));
    padding-right: calc(var(--spacing) + env(safe-area-inset-right, 0));
  }

  &:before,
  &:after {
    content:'';
    display: none;
    left: 0;
    height: 30%;
    opacity: .6;
    pointer-events: none;
    position: absolute;
    right: 0;
    z-index: 1;
  }

  &:before {
    @include scrimGradient(#0D0D0D, 'to bottom');
    top: 0;
  }

  &:after {
    @include scrimGradient(#0D0D0D, 'to top');
    bottom: 0;
  }

  :global(.creator-da) & {
    &:before {
      display: block;
    }
  }

  &--image {
    color: var(--color__white);

    &:after,
    &:before {
      display: block;
    }
  }

  :global(.ios) &,
  :global(.android) & {
    padding-top: var(--sat);
  }

  :global(.ios-listener) &,
  :global(.android-listener) & {
    padding-bottom: var(--sab);

    &:global(.chat-visible) {
      padding-bottom: 0;
    }

    &:after,
    &:before {
      height: 50%;
      opacity: 1;
    }
  }

  :global(.creator-da) & {
    padding-top: 36px;
  }

  :global(.embed) & {
    height: 100%;
    width: 100%;

    &:after,
    &:before {
      height: 50%;
      opacity: .9;
    }
  }
}

.full-screen__image {
  bottom: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

// Header / Footer components

.full-screen__header,
.full-screen__footer {
  align-items: center;
  display: flex;
  gap: var(--spacing__large);
  justify-content: space-between;
  
  position: relative;
  z-index: 2;

  @media screen and (min-width: 640px) {
    @include gap-fallback(var(--spacing__large));
  }
}

.full-screen__header__controls {
  align-items: center;
  display: flex;
  gap: var(--spacing);
}

@media screen and (max-width: 640px) {
  .full-screen__footer {
    display: block;
  }
}

.full-screen__header {
  padding: var(--spacing) calc(var(--spacing__large) + var(--sar)) var(--spacing);

  &--native {
    padding-right: 120px;
  }
}

.full-screen__footer {
  padding: 0 calc(var(--spacing__large) + var(--sar)) var(--spacing__large);

  :global(.embed) & {
    padding: 0 var(--spacing) var(--spacing);
  }
}

.full-screen__header {
  :global(.embed) & {
    align-items: flex-start;
    padding: var(--spacing) var(--spacing) 0;
  }
}

.full-screen__footer__left-col {
  align-items: center;
  display: flex;
  gap: var(--spacing__large);
  flex: 1;
  min-width: 0;

  :global(.embed) & {
    gap: var(--spacing);
  }

  @include gap-fallback(var(--spacing__large));

  :global(.embed) & {
    svg {
      height: 12px;
      width: 12px;
    }
  }
}

.full-screen__footer__right-col {
  padding: var(--spacing) 0 0;
}

.full-screen__footer {
  :global(.embed) & {
    display: flex;
    
    .full-screen__footer__right-col {
      padding-top: 0;
    }
  }
}

.full-screen__logo {
  align-items: center;
  display: flex;
  font-size: var(--font__size-small);
  gap: var(--spacing__large);
  min-width: 0;
  max-width: 60ch;

  @include gap-fallback(var(--spacing__large));

  :global(.embed) &,
  :global(.ios-listener) &,
  :global(.android-listener) & {
    gap: var(--spacing);
  }
}

.full-screen__logo__details {
  min-width: 0;
}

.full-screen__logo__title {
  font-size: var(--font__size-body);
  margin: 0;

  :global(.embed) &,
  :global(.ios-listener) &,
  :global(.android-listener) & {
    font-size: var(--font__size-mini);
  }

  :global(.has-image) &,
  :global(.dark-contrast) & {
    color: var(--color__white);
  }
}

.full-screen__logo__links {
  align-items: center;
  list-style: none;
  display: flex;
  gap: var(--spacing__large);
  margin: 0;
  padding: 0;

  @include gap-fallback(var(--spacing_large));

  a, button {
    color: var(--color__white);

    &:hover {
      opacity: .5;
    }
  }

  :global(.embed) & {
    font-size: var(--font__size-mini);

    a, button {
      height: auto;
    }

    svg {
      height: 12px;
      width: 12px;
    }
  }
}

// Center block component

.full-screen__content {
  display: flex;
  flex: 1;
  overflow: hidden;
  padding: var(--spacing__large);
  position: relative;

  &__center-col {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    text-align: center;
    position: relative;
  }

  :global(.chat-visible) & {
    @media screen and (max-height: 410px) {
      max-width: 50%;
    }
  
    @media screen and (max-width: 640px) and (max-height: 410px) {
      max-width: 100%;
    }
  }

  :global(.embed) & {
    max-width: 100%;
    overflow: visible;
    padding: 0 var(--spacing);
  }
}


// Footer component

.full-screen__meta {
  display: flex;
  align-items: center;
  font-size: var(--font__size-small);
  gap: var(--spacing__large);
  opacity: .8;

  @include gap-fallback(var(--spacing__xlarge));

  :global(.embed) & {
    font-size: var(--font__size-mini);
    gap: var(--spacing__large);

    svg {
      height: 12px;
      width: 12px;
    }
  }

  @media screen and (min-width: 400px) {
    font-size: var(--font__size-small);

    svg {
      height: 16px;
      width: 16px;
    }
  }

  @media screen and (min-width: 600px) {
    font-size: var(--font__size-body);
    gap: var(--spacing__xlarge);
  }
}

.full-screen__controls {
  ul {
    align-items: center;
    display: flex;
    gap: var(--spacing);
    list-style: none;
    justify-content: flex-start;
    margin: 0;
    padding: 0;

    @include gap-fallback(var(--spacing));

    @media screen and (min-width: 600px) {
      justify-content: flex-end;
    }
  }

  li:empty {
    display: none;
  }
}

.full-screen__header__links {
  align-items: center;
  display: flex;
  gap: var(--spacing__small);
}
