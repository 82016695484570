.search__filters {
  background-color: var(--color__background);
  border-radius: 66px;
  display: flex;
  justify-content: center;
  margin: var(--spacing__small) auto var(--spacing__large);
  max-width: 100%;
  padding: 3px;
  width: 100%;

  @media screen and (min-width: 540px) {
    max-width: 320px;
    min-width: 300px;
  }

  > button:not(:disabled) {
    background: transparent;
    color: var(--color__label);
  }

  :global(.ios-listener) &,
  :global(.android-listener) & {
    button {
      height: 34px;
    }
  }
}