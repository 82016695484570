// Accessibility classes =================================

.visibility-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

// Layout helpers ========================================

.flow > * + * {
  margin-top: var(--spacing__large);
}

.flow--small > * + * {
  margin-top: var(--spacing);
}

.flow--large > * + * {
  margin-top: var(--spacing__xlarge);
}

.full-bleed {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 100vw;

  .ios & {
    width: calc(100vw - (env(safe-area-inset-left) + env(safe-area-inset-right)));
  }
}

.mar-n {
  margin: 0;
}

.mar-t {
  margin-top: var(--spacing__large);
}

.mar-t-l {
  margin-top: var(--spacing__xxlarge);
}

.mar-b-s {
  margin-bottom: var(--spacing__large);
}

.mar-b {
  margin-bottom: var(--spacing__large);
}

.mar-b-l {
  margin-bottom: var(--spacing__xxlarge);
}

.no-pad {
  padding: 0;
}

.pad-l {
  padding: var(--spacing__large);
}

.pad-r-l {
  padding-right: var(--spacing__large);
}

.pad-l-l {
  padding-left: var(--spacing__large);
}

.padding--full {
  padding: var(--layout__padding);
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.text--left {
  text-align: left;
}

.flex--item {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.overflow-hidden {
  overflow: hidden;
}

hr {
  background-color: var(--color__line-light);
  border: 0;
  margin: var(--spacing__xxlarge) 0;
  height: 1px;
}

.has-fixed-button {
  padding-bottom: calc(var(--input-height) + env(safe-area-inset-bottom) + var(--spacing__xxlarge));
}

.empty-state {
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
}

.empty-state__content {
  max-width: 420px;
  padding: var(--spacing__large);
  text-align: center;
  width: 100%;

  > svg {
    display: block;
    fill: rgb(var(--color__primary));
    margin: 0 auto var(--spacing__large);
  }
}

// Colours ===============================================

.bg-default {
  background-color: var(--color__background); 
}

.bg-light {
  background-color: var(--color__light-background); 
}

.color--black {
  color: var(--color__label);
}

.color--mixlr-red {
  color: var(--color__mixlr-red);
}

.color--red {
  color: var(--color__red);
}

// Animation helpers =====================================

.hide {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease-in-out;
  z-index: -1;
}

.show {
  max-height: 100rem;
  opacity: 1;
  visibility: visible;
  transition: all .2s ease-in-out;
  z-index: default;
}

.fade-out {
  animation: fadeOut .32s;
}

.fade-in {
  animation: fadeIn .3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


// Loading text

.loading-text:after {
  animation: dots 1.5s steps(5, end) infinite;
  content: '.';
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: currentColor;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 currentColor,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 currentColor,
      .5em 0 0 currentColor;}
}