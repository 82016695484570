.off-air {
  border: 1px solid var(--color__black);
  border-radius: var(--border__radius--mid);
  color: var(--color__black);
  font-size: var(--font__size-mini);
  font-weight: 600;
  padding: 2px var(--spacing);
  position: fixed;
  right: var(--spacing__large);
  top: var(--spacing__large);
  text-transform: uppercase;
  z-index: 10;

  :global(.embed) & {
    font-size: var(--font__size-tiny);
    padding: 1px 6px;

    svg {
      height: 12px;
      width: 12px;
    }
  }

  :global(.has-image) & {
    border: 1px solid var(--color__white);
    color: var(--color__white);
  }
}