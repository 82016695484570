@import "scss/mixins";

.alert {
  animation: enterLeave 5s ease-in-out;
  align-items: center;
  background-color: rgba(var(--color__black-rgb), .8);
  border-radius: 66px;
  color: var(--color__white);
  display: flex;
  gap: var(--spacing);
  left: 50%;
  padding: var(--spacing__small) var(--spacing) var(--spacing__small) var(--spacing);
  position: fixed;
  top: var(--spacing__xlarge);
  transform: translateX(-50%);
  z-index: 1000;

  @include gap-fallback(var(--spacing));
}

.alert--success svg {
  fill: var(--color__green);
}

.alert--notify svg {
  fill: var(--color__orange);
}

.alert--error svg {
  fill: var(--color__red);
}

.alert__message {
  font-size: var(--font__size-small);
  flex: 1;
  margin: 0;
  text-align: center;
}

@keyframes enterLeave {
  0%    { opacity: 0; top: -20px; }
  7.5%  { opacity: 1; top: var(--alert__animation); }
  92.5% { opacity: 1; top: var(--alert__animation); }
  100%  { opacity: 0; top: -20px; }
}