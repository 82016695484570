// Mixlr Header =======================================

.mixlr-footer {
  align-items: center;
  display: flex;
  font-size: var(--font__size-mini);
  flex: 0;
  justify-content: center;
  position: relative;
  width: 100%;
}