@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .active {
    @apply tw-text-black dark:tw-text-white;
  }

  .no-scrollbar-child > *::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar-child > * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
