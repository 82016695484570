.search__bar {
  align-items: center;
  display: flex;
  gap: var(--spacing);
  padding: var(--spacing) 0 var(--spacing);
  position: sticky;
  top: 0;
  z-index: 100;

  :global(.ios-listener) &,
  :global(.android-listener) & {
    padding: var(--spacing) 0;

    button {
      height: 42px;
      flex: 0 0 40px;
      width: 40px;
    }
  }
}