@import "scss/mixins";

.event-view__content {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  overflow: hidden;
  z-index: 3;
}

.event-view__center {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
  position: relative;
}

.event-view__chat {
  max-width: 400px;
  padding-right: var(--spacing__large);
  width: 100%;

  @media screen and (min-width: 1000px) {
    max-width: 440px;
  }

  &.event-view__chat--hidden {
    display: none;
  }
}

.event-view__details {
  overflow: hidden;
  width: 100%;
}

.event-view__meta {
  display: flex;
  gap: var(--spacing__xlarge);
  opacity: .6;

  @include gap-fallback(var(--spacing__xlarge));
}


.event-view__heart {
  margin-right: auto !important;
}

.event-view__minimize {
  color: var(--color__white);

  a {
    color: var(--color__white);
  }
}

.event-view__creator {
  display: block;
}

// Event countdown

.countdown {
  color: var(--color__label);
  font-size: var(--font__size-large);
  max-width: 620px;
  padding: var(--spacing__xlarge) var(--spacing__large);
  text-align: center;
  width: 100%;

  &:before {
    background: radial-gradient(rgba(var(--color__black-rgb), .6), rgba(var(--color__black-rgb), .2), rgba(var(--color__black-rgb), 0));
    background-repeat: no-repeat;
    content: "";
    display: none;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  > * {
    position: relative;
    z-index: 10;
  }

  > button[class*="button--link"] {
    color: var(--color__label);
    font-size: var(--font__size-body);
    margin-bottom: var(--spacing);

    :global(.has-image) & {
      color: var(--color__white);
    }

    &:hover {
      color: rgb(var(--color__primary));
    }
  }

  button[class*="button--icon"] {
    margin-top: var(--spacing);
  }

  :global(.has-image) & {
    color: var(--color__white);
    text-shadow: 0 1px 1px rgba(var(--color__black-rgb), .2);

    &:before {
      display: block;
      opacity: .8;
    }
  }

  :global(.embed) & {
    font-size: var(--font__size-body);

    h2 {
      font-size: var(--font__size-large);
    }

    &:before {
      background: rgba(var(--color__black-rgb), .8);
    }
  }
}

.countdown__time {
  font-feature-settings: 'tnum';
  font-size: var(--font__size-h1);
  font-weight: 800;
  font-variant-numeric: tabular-nums;
  line-height: 1;
  margin-bottom: var(--spacing);
}

.countdown__date {
  margin-bottom: var(--spacing);
}

// Event access code

.access-modal {
  background-color: var(--color__white);
  border-radius: var(--border__radius);
  align-self: center;
  color: var(--color__label);
  margin: auto;
  max-width: 400px;
  padding: var(--spacing__large);
  position: relative;
  text-align: center;
  width: 100%;
}

// Player state

.event-view__player-state {
  align-items: center;
  display: flex;
  gap: var(--spacing);

  @include gap-fallback(var(--spacing));
}

.event-view__player-state__on-air {
  align-items: center;
  display: flex;
  gap: var(--spacing);
  white-space: nowrap;

  @include gap-fallback(var(--spacing));
}
