.modal {
  background-color: var(--color__background);
  border-radius: var(--border__radius);
  box-shadow: 0 0 20px rgba(var(--color__black-rgb), .3);
  height: auto;
  max-height: 640px;
  max-width: 400px;
  outline: none;
  overflow: auto;
  overscroll-behavior-y: contain;
  position: relative;
  width: 100%;
  z-index: 1000;

  &--wide {
    max-width: 620px;
  }
}

.modal__overlay {
  align-items: center;
  background-color: rgba(var(--color__black-rgb), .7);
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  padding: var(--spacing__large);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999999;

  .embed & {
    padding: var(--spacing);
  }
}

.modal__content {
  padding: var(--spacing__large);
}

.modal__description {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  &:last-of-type {
    margin: 0;
  }
}

.modal__footer {
  font-size: var(--font__size-mini);
  padding: 0 var(--spacing__large) var(--spacing__large);
  text-align: center;

  a {
    color: var(--color__black);
  }

  svg {
    fill: var(--color__label);
    height: auto;
    margin-left: var(--spacing__small);
    position: relative;
    top: -2px;
    vertical-align: middle;
    width: 60px;
  }
}