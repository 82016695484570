.modal__header {
  background-color: rgba(var(--color__primary), 0.3);
  padding: var(--spacing__large) 52px;
  position: relative;
  text-align: center;

  :global(.embed) & {
    padding: var(--spacing) 32px;
  }
}

.modal__header-title {
  margin: 0;
  text-align: center;

  :global(.embed) & {
    font-size: var(--font__size-body);
  }
}

.modal__header-close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--spacing);
}

.modal__header-logo {
  margin: 0 auto var(--spacing__large);
}