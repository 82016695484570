.error-page {
  align-items: center;
  display: flex;
  flex: 1;
  left: 0;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;

  > * {
    align-items: center;
    justify-content: center;
  }
}

.error-page__content {
  position: relative;
  text-align: center;
  z-index: 10;

  > h1:first-letter {
    text-transform: uppercase;
  }

  :global(.embed) & {
    h1 {
      font-size: var(--font__size-h3);
    }
  }

  :global(.has-image) & {
    text-shadow: 0 0 20px rgba(var(--color__black-rgb), 1);
  }
}

.error-page__dark {
  background-color: var(--color__black);
  color: var(--color__white);
}