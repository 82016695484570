// Grid layout =================================

.grid__row {
  display: flex;
  flex-flow: row wrap;
}

$columns: 12;
$gap: 32px;
$breakpoints: (
  xss: 300px,
  xs: 640px,
  sm: 768px,
  md: 960px,
  lg: 1170px,
  xl: 1280px
);

@mixin create-selectors($breakpoint: null) {
  $infix: if($breakpoint == null, '', '-#{$breakpoint}');

  @for $i from 1 through $columns {
    .col#{$infix}-#{$i} {
      grid-column-end: span $i;
    }
    .col-offset#{$infix}-#{$i} {
      grid-column-start: $i + 1;
    }
    .row#{$infix}-#{$i} {
      grid-row-end: span $i;
    }
    .row-offset#{$infix}-#{$i} {
      grid-row-start: $i + 1;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  grid-gap: var(--spacing__large, 16px);
  gap: var(--spacing__large, 16px);
}

.grid-scroll {
  display: grid;
  grid-gap: var(--spacing__large);
  grid-template-columns: 0 repeat(4, calc(90% - var(--spacing__large) * 2)) 0;
  grid-template-rows: minmax(150px, 1fr);
  margin-left: -12px;
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  padding-bottom: var(--spacing__large);
  margin-bottom: var(--spacing__large);
  -webkit-overflow-scrolling: touch;

  @media screen and (min-width: 600px) {
    grid-template-columns: 0 repeat(4, calc(60% - var(--spacing__large) * 2)) 0;
  }

  @media screen and (min-width: 960px) {
    grid-template-columns: 0 repeat(4, calc(48% - var(--spacing__large) * 2)) 0;
  }
}

.grid-scroll::before,
.grid-scroll::after {
  content: '';
}

.grid-scroll::-webkit-scrollbar {
  height: 8px;
}

.grid-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.grid-scroll::-webkit-scrollbar-thumb {
  background: var(--color__line-light);
  border-radius: 4px;
}

.grid-scroll::-webkit-scrollbar-button {
  display: none;
  height: 0;
  width: 0;
}

@include create-selectors;

@each $breakpoint, $width in $breakpoints {
  @media (min-width: $width) {
    @include create-selectors($breakpoint);
  }
}

@media (max-width: 768px) {
  .col-r-order {
    order: 2;
    + .col-r-order {
      order: 1;
    }
  }
}

.flex-row,
.flex__row {
  display: flex;
  flex-flow: row wrap;
  margin: 0 calc(-1 * var(--spacing));
  width: 100%;
}

.flex__column {
  display: flex;
  flex-direction: column;
}

.flex--center {
  align-items: center;
  justify-content: center;
}

.flex__row:last-of-type {
  margin-bottom: 0;
}

.flex-col,
.flex__col {
  flex: 1;
  margin: 0 var(--spacing);
}

.flex__col__default {
  flex: 0;
}
