// Provide fallback for Flexbox gap support (specfic to DA, older versions of chrome/safari)

@mixin gap-fallback($spacing) {

  $devices: (
    '.creator-da',
    '.safari-13',
    '.safari-14'
  );

  @each $device in $devices {
    :global(#{$device}) & {
      // Incase a version number is slighty different
      // e.g Safari 14 also matches Safari 14.5 (when gap was introduced)
      // Reset back to 0
      gap: 0; 
      
      // https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/
      > * + *  {
        margin-left: $spacing;
      }
    }
  }
}

/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
*/

@mixin scrimGradient($startColor: 'black', $direction: 'to bottom') {

  $scrimCoordinates: (
    0: 1,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0
  );

  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  background: linear-gradient(unquote($direction), $stops);

}

@mixin bluredBackground($opacity: .9) {
  background: rgba(var(--color__background-rgb), $opacity);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  :global(.creator-da) & {
    background: var(--color__background);
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }
}