// Link styles =====================================

a {
  color: rgb(var(--color__primary));
  text-decoration: none;
  transition: all .2s ease-in-out;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  @media (prefers-reduced-motion) {
    transition: none;
  }

  &:hover {
    color: var(--color__label);
    text-decoration: none;
  }

  .dark-mode & {
    color: var(--color__label);
  }
}

a.link--facebook {
  color: var(--color__facebook);
}

a.link--dark,
.link--dark {
  color: var(--color__label);

  &:hover {
    color: rgb(var(--color__primary));
  }
}

a.link--light,
.link--light {
  color: var(--color__black-40);
}

.link__text {
  color: var(--color__label);
}
