.wrapper {
  z-index: 10000;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: darkred;
  color: white;
  text-align: center;
}

.title {
  margin-bottom: 0px;
}

.button {
  margin-left: 10px;
  margin-right: 10px;
}
