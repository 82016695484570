@import "scss/mixins";

// Forms ======================================

.form {
  margin: 0 auto var(--spacing__large);
  width: 100%;

  &:last-of-type {
    margin: 0;
  }
}

.form__links,
.form__help {
  font-size: var(--font__size-small);

  a:link,
  button {
    justify-content: center;
    text-align: center;
    text-decoration: underline;
  }

  a:hover,
  button:hover {
    text-decoration: none;
  }
}

.form__links {
  margin-top: var(--spacing__large);

  li {
    margin-bottom: var(--spacing__small);

    &:last-child {
      margin: 0;
    }
  }
}

.form--title {
  font-size: var(--font__size-h3);
  text-align: center;
}

.form__checkbox {
  margin-right: var(--spacing);
}

// Layout

fieldset {
  border: 0;
  display: block;
  margin: 0 0 var(--spacing__large);
  padding: 0;

  &:last-child {
    margin: 0;
  }
}

legend {
  border: 0;
  display: block;
  padding: 0;
}

.form__label {
  color: var(--color__label);
  display: block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 600;
  margin-bottom: var(--spacing__small);
}

.form__label--error {
  color: var(--color__mixlr-red);
}

.form__label--error ~ svg {
  fill: var(--color__mixlr-red);
}

.form__row {
  grid-gap: var(--spacing__large);
  gap: var(--spacing__large);
  margin-bottom: var(--spacing__large);
  position: relative;

  @include gap-fallback(var(--spacing__large));

  > .form__row {
    margin: 0;
  }
}

.form__row--activation {
  margin: var(--spacing__xxlarge) auto var(--spacing__xxlarge);
  max-width: 18rem;
  text-align: center;

  .ios & {
    max-width: 180px;
  }
}

.form__row:last-of-type {
  margin-bottom: 0;
}

.form__error,
.form__hint {
  color: var(--color__mixlr-red);
  font-size: var(--font__size-small);
  max-height: 6rem;
  opacity: 1;
  visibility: visible;
  transition: all .2s ease-in-out;

  @media (prefers-reduced-motion) {
    transition: none;
  }

  .ios & {
    margin-top: 4px;
  }
}

.form__hint {
  color: var(--color__black-40);
  margin-top: var(--spacing__small);
}

.form__error:not(.hidden) {
  margin-top: var(--spacing__small);
}

.hidden {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.form__error.show,
.form__hint--show,
.form__input--error + .form__error {
  display: block;
  max-height: 6rem;
  margin-top: var(--spacing__small);
  opacity: 1;
  visibility: visible;

  .ios & {
    margin-top: 4px;
  }
}

.form__error--list {
  margin-bottom: var(--spacing__large);
}

.form__error--list li:not(.list__item) {
  padding-left: 20px;
  position: relative;
}

.form__error--list svg {
  left: 0;
  position: absolute;
  top: 3px;
}

.fieldWithErrors {
  .form__label {
    color: var(--color__mixlr-red);
  }
  .form__input {
    border-color: var(--color__mixlr-red);
  }
  ~ svg {
    fill: var(--color__mixlr-red);
  }
}

.form__req {
  border: none;
  color: var(--color__mixlr-red);
  text-decoration: none;
}

// Inputs

.form__input {
  background-color: var(--color__background);
  border: 1px solid var(--color__line);
  border-radius: var(--border__radius--small);
  box-shadow: 0 0 0 0 rgba(#000000, 0);
  color: var(--color__label);
  display: block;
  font-family: inherit;
  font-size: min(100%, 16px);
  font-weight: 500;
  height: var(--input-height);
  padding: 0 var(--spacing__large);
  transition: all .1s ease-in-out;
  width: 100%;
  -webkit-appearance: none;

  &:disabled {
    background-color: var(--color__light-background);
    border-color: var(--color__line);
  }

  &:focus {
    border-color: var(--color__black);
    box-shadow: 0 0 0 3px rgba(#000000, .4);
    outline: none;
  }

  &__rounded {
    border-radius: 9999px;
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }

  @media (prefers-color-scheme: dark) {
    .ios & {
      box-shadow: 0 0 0 0 rgba(#ffffff, 0);

      &:focus {
        border-color: var(--color__white);
        box-shadow: 0 0 0 3px rgba(#ffffff, .4);
      }
    }
  }
}

::-moz-selection {
  background: RGBA(var(--color__mixlr-red-rgb), .2);
}

::selection {
  background: RGBA(var(--color__mixlr-red-rgb), .2);
}

.form__input:focus::placeholder {
  color: transparent;
}

.form__input__textarea {
  height: 120px;
  padding: var(--spacing__large);
}

.form__input__textarea--small {
  height: 80px;
  padding: var(--spacing__large);
}

.form__input__button {
  position: relative;

  > *:not(button):not(span) {
    flex: 1;
    padding-right: calc(48px + var(--spacing__large));
  }

  button {
    border: 0;
    border-radius: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

input[type="file"] {
  display: inline-block;
  font-family: inherit;
  font-size: var(--font__size-small);
  margin-bottom: var(--spacing);

  .ios &,
  .android & {
    width: 100%;
  }
}

input[type="checkbox"],
input[type="radio"] {
  position: relative;
  top: -.15rem;
  vertical-align: middle;

  + label {
    cursor: pointer;
  }

  &:disabled {
    cursor: none;

    + .form__label {
      cursor: none;
      opacity: .5;
    }
  }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.form__row--checkbox,
.form__row--radio {
  display: flex;
  margin-bottom: var(--spacing);
  position: relative;

  input {
    flex: none;
    margin-right: var(--spacing);
    top: 0;
  }

  .fieldWithErrors {
    display: flex;
  }

  &:last-of-type {
    margin: 0;
  }
}

// Custom checkboxes & radios

.form__input--radio {
  cursor: pointer;  
  height: 28px;
  left: -2px;
  margin: 0;
  opacity: 0;
  top: -2px;
  width: 28px;
  z-index: 1;
}

.form__input--radio:checked + .form__label--radio:after {
  opacity: 1;
  visibility: visible;
}

.form__label--radio {
  margin: 0;
  
  &:before {
    border: 2px solid currentColor;
    border-radius: 50%;
    background: transparent;
    content: "";
    height: 24px;
    left: 0;
    position: absolute;
    top: -2px;
    width: 24px;
  }
  &:after {
    background: currentColor;
    border-radius: 50%;
    border: 6px solid currentColor;
    content: "";
    height: 0;
    left: 6px;
    position: absolute;
    opacity: 0;
    transition: all .1s ease-in-out;
    top: 4px;
    width: 0;
  }
}

.form__input--radio[data-readonly="true"] + .form__label--radio,
.form__input--radio:disabled + .form__label--radio {
  &:after {
    background: var(--color__black-40);
    border-color: var(--color__black-40);
  }
  &:before {
    border-color: var(--color__black-40);
  }
}

.form__input--checkbox {
  cursor: pointer;  
  height: 28px;
  left: -2px;
  margin: 0;
  opacity: 0;
  top: -2px;
  width: 28px;
  z-index: 1;
}

.form__input--checkbox:checked + .form__label--checkbox:after {
  opacity: 1;
  visibility: visible;
}

.form__label--checkbox {
  margin: 0;

  &:before {
    border: 2px solid currentColor;
    border-radius: 4px;
    background: transparent;
    content: "";
    height: 24px;
    left: 0;
    position: absolute;
    top: -2px;
    width: 24px;
  }
  &:after {
    background: transparent;
    border: solid;
    border-width: 0 0 3px 3px;
    border-top-color: transparent;
    content: "";
    height: 8px;
    position: absolute;
    opacity: 0;
    transform: rotate(-50deg);
    top: 5px;
    left: 5px;
    width: 14px;
  }
}

.form__label--checkbox:disabled + .form__label--checkbox {
  &:after,
  &:before {
    border-color: var(--color__black-40);
  }
}

.form__input--no-label {
  padding: 0 var(--spacing__large);
}

.form__input--medium {
  max-width: 320px;
}

.form__input--small {
  max-width: 180px;
}

.form__input--center {
  margin: 0 auto;
}

.form__input--error {
  border-color: var(--color__mixlr-red);

  + .button--icon {
    border-color: var(--color__mixlr-red);
  }
}

.form__input--auth {
  font-size: var(--font__size-h4);
}

// Select

.form__select {
  background-color: var(--color__background);
  border: 1px solid var(--color__line);
  border-radius: var(--border__radius--small);
  display: flex;
  font-size: var(--font__size-body);
  font-weight: 500;
  height: var(--input-height);
  line-height: var(--input-height);
  padding: 0;
  position: relative;

  @media (prefers-reduced-motion) {
    transition: none;
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    color: var(--color__label);
    cursor: inherit;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    outline: none;
    padding: 0 var(--spacing__large);
    width: 100%;
  }

  select::-ms-expand {
    display: none;
  }
}

.form__select--small {
  width: 14rem;

  .ios & {
    width: 140px;
  }
}

.form__select:after {
  border-bottom: 2px solid var(--color__mixlr-red);
  border-right: 2px solid var(--color__mixlr-red);
  content: "";
  height: 8px;
  margin-top: -2px;
  pointer-events: none;
  position: absolute;
  right: var(--spacing__large);
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  width: 8px;
}

// Artwork

.form__input--file {
  margin-bottom: var(--spacing);
}

.form__artwork__preview {
  display: block;
  margin: 0 auto;
  width: 320px;
}

.form__file__holder {
  background-color: var(--color__light-background);
  border-radius: var(--border__radius--mid);
  overflow: hidden;
  padding-top: 75%;
  position: relative;
  transform: translateZ(0);
}

.form__file__preview {
  border: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.form__file__help {
  color: var(--color__black-40);
  display: block;
}

.form__label--file,
.form__file__remove {
  display: inline-flex;
  margin: 0;
  width: auto;
}

.form__file__remove {
  display: none;
}

// Password strength meter

.password-strength {
  margin: var(--spacing) 0;
  overflow: hidden;
  position: relative;
  width: 100%;

  .no-js & {
    display: none;
  }
}

.password-strength:before,
.password-strength:after {
  // background: image-url('svg/progress-break.svg') 0 0 no-repeat;
  background-size: 100%;
  border-radius: 2px;
  content: '';
  height: 4px;
  left: 33%;
  margin-left: -8px;
  position: absolute;
  top: 0;
  width: 16px;

  @media (prefers-color-scheme: dark) {
    .ios & {
      -webkit-filter: invert(100%);
      filter: invert(100%);
    }
  }
}

.password-strength:after {
  left: 66%;
}

.password-strength__progress {
  width: 100%;
}

.password-strength__title {
  color: var(--color__black-40);
  flex: 1 0 100%;
  font-size: var(--font__size-small);
  margin: var(--spacing__small) 0 0;
  transition: all .2s ease-in-out;

  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.password-strength__title.show {
  margin-bottom: var(--spacing);
  opacity: 1;
  visibility: visible;
}

.password-strength__progress[value] {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  display: block;
  height: 4px;
  width: 100%;
}

.password-strength__progress[value]::-webkit-progress-bar {
  background-color: var(--color__line);
  border-radius: 2px;
}

.password-strength__progress::-webkit-progress-value {
  border-radius: 2px;
  transition: all .1s ease-in-out;

  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.password-strength__progress[data-value="0"]::-webkit-progress-value,
.password-strength__progress[data-value="33"]::-webkit-progress-value {
  background-color: var(--color__mixlr-red);
  border-radius: 2px;
}

.password-strength__progress[data-value="0"]::-moz-progress-bar,
.password-strength__progress[data-value="33"]::-moz-progress-bar {
  background-color: var(--color__mixlr-red);
  border-radius: 2px;
}

.password-strength__progress[data-value="33"] + .password-strength__title {
  color: var(--color__mixlr-red);
}

.password-strength__progress[data-value="66"]::-webkit-progress-value {
  background-color: var(--color__orange);
  border-radius: 2px;
}

.password-strength__progress[data-value="66"]::-moz-progress-bar {
  background-color: var(--color__orange);
  border-radius: 2px;
}

.password-strength__progress[data-value="66"] + .password-strength__title {
  color: var(--color__orange);
}

.password-strength__progress[data-value="100"]::-webkit-progress-value {
  background-color: var(--color__green);
  border-radius: 2px;
}

.password-strength__progress[data-value="100"]::-moz-progress-bar {
  background-color: var(--color__green);
  border-radius: 2px;
}

.password-strength__progress[data-value="100"] + .password-strength__title  {
  color: var(--color__green);
}

.password-button {
  color: var(--color__label);
  position: absolute;
  right: 0;
  top: 0;

  .no-js & {
    display: none;
  }
}

// Stripe Elements

.stripe__element {
  align-items: center;
  border: 1px solid var(--color__line);
  border-radius: var(--border__radius--mid);
  display: flex;
  height: var(--input-height);
  padding: 0 var(--spacing__large);
}

.stripe__element > * {
  width: 100%;
}
