.form-inline__row {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  max-width: 320px;
  transform: translateX(22px);
  
  > button {
    transform: translateX(-100%) scale(0.86);
  }
}

.form-inline__label {
  display: block;
  font-size: min(100%, 16px);
  font-weight: 500;
  margin-bottom: var(--spacing);
  text-align: center;
  width: 100%;
}

.form-inline__input {
  background-color: var(--color__background);
  border-radius: 9999px;
  border: 1px solid var(--color__line);
  display: flex;
  flex: 1;
  font-family: inherit;
  padding: 0 var(--spacing__large);
  text-align: center;
  vertical-align: middle;
  width: 100%;

  &:focus {
    border-color: var(--color__black);
    box-shadow: 0 0 0 3px rgba(var(--color__primary), 0.4);
    outline: none;
  }
}