.form__message {
  display: flex;
  font-size: var(--font__size-small);
  gap: var(--spacing__small);
  margin: var(--spacing) 0;

  &--info {
    color: var(--color__black-40);
  }

  &--error {
    color: var(--color__red);
  }

  &--notify {
    color: var(--color__blue);
  }

  &--success {
    color: var(--color__green);
  }

  &--left {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }

  > svg {
    position: relative;
    top: 4px;
  }
}