@import "scss/mixins";

// Social links ========================================

.social-links:not(.social-links--icons) {
  list-style: none;
  margin: 0 0 var(--spacing__xlarge);
  padding: 0;
  
  a {
    overflow: hidden;
    padding: 0;
    width: 100%;
  }

  li {
    margin-bottom: var(--spacing);
  }

  span {
    display: block;
    font-size: var(--font__size-small);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.social-links--icons {
  align-items: center;
  display: flex;
  gap: var(--spacing__large);
  list-style: none;
  margin: 0 var(--spacing) 0 0;
  padding: 0;

  @include gap-fallback(var(--spacing_large));

  li {
    display: flex;
    margin: 0;
    gap: var(--spacing);

    @include gap-fallback(var(--spacing));
  }
}