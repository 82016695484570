:global(.EmojiPickerReact) {
  transition: none !important;
  
  * {
    font-family: var(--font__family) !important;
    transition: none !important;
  }

  :global(.epr-emoji-category-label) {
    backdrop-filter: none;
    font-size: var(--font__size-mini);
    font-weight: bold;
  }
}

.emoji-picker__button {
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  color: rgb(var(--color__primary));
  display: flex;
  flex: 1 0 calc(var(--spacing)*2.5);
  justify-content: center;
  padding: 0;
  transition: all .2s ease-in-out;

  > svg {
    height: calc(var(--spacing)*2.5);
    width: calc(var(--spacing)*2.5);
  }

  &:hover, &.active {
    color: var(--color__label);
  }

  :global(.dark-mode) & {
    color: var(--color__label);

    &:hover {
      color: rgb(var(--color__primary));
    }
  }
}
