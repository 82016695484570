// User image styles ====================================

.logo-image {
  align-items: center;
  background-color: var(--color__white);
  border-radius: 50%;
  display: inline-flex;
  color: var(--color__white);
  justify-content: center;
  height: 32px;
  overflow: hidden;
  position: relative;
  width: 32px;

  > img {
    display: block;
    object-fit: cover;
    margin: 0 auto;
    height: 100%;
    width: 100%;
  }

  :global(.embed) & {
    cursor: pointer;
  }

  &--fallback {
    background-color: rgb(var(--color__primary));

    :global(.body-full-screen) &,
    :global(.embed) & {
      background-color: rgba(var(--color__black-rgb), .3);
      backdrop-filter: blur(4px);
    }
  }
}

.logo-image--tiny {
  flex: 0 0 12px;
  height: 12px;
  width: 12px;
}

.logo-image--embed {
  flex: 0 0 36px;
  height: 36px;
  width: 36px;
}

.logo-image--mini {
  flex: 0 0 24px;
  height: 24px;
  width: 24px;
}

.logo-image--small {
  flex: 0 0 44px;
  height: 44px;
  width: 44px;
}

.logo-image--medium {
  flex: 0 0 64px;
  height: 64px;
  width: 64px;
}

.logo-image--large {
  flex: 0 0 92px;
  height: 92px;
  width: 92px;
}