// List styles ====================================

.list--reset {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.list--default {
  margin: 0;
  padding: 0;
}

.list--default > li {
  margin: 0 0 var(--spacing__large);

  &:last-child {
    margin: 0;
  }
}

.list--reset li:not(.list__item):last-child,
.list--default > li:last-child,
.list--inline > li:last-child {
  margin: 0;
}

.list--inline {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  text-align: left;
}

.list--inline > li {
  margin: 0 var(--spacing__large) 0 0;
}

.list--border > li:not(.list__item) {
  border-bottom: 1px solid var(--color__line-light);
  margin: 0;
}

.list--border a,
.list--border button {
  display: block;
  padding: var(--spacing__large);
  width: 100%;
}

.list--border > li:last-child {
  border: 0;
}

.list__header {
  align-items: center;
  display: flex;
  justify-content: space-between;

  > :last-child {
    text-align: right;
  }
}

.list__item {
  margin-bottom: var(--spacing__large);
  position: relative;
  transition: transform .1s ease-in-out;
  width: 100%;
}

.list__item--link-block {
  padding: 0;
}

.list__link {
  color: var(--color__label);
  text-decoration: none;
}

.list__content {
  flex: 1;
  overflow: hidden;
}

.list__title {
  color: var(--color__label);
  font-size: var(--font__size-h4);
  margin: 0;
}

.list__title--small {
  font-size: var(--font__size-body);
}

.list__meta {
  color: var(--color__black-40);
  font-size: var(--font__size-small);

  > span {
    display: inline-block;
    margin-left: var(--spacing__large)
  }
}

.list__media {
  flex: 0;
}

.list__toggle {
  color: var(--color__white);
  position: relative;
  z-index: 100;
}

.list__actions {
  background-color: var(--color__white);
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  border-radius: var(--border__radius--small);
  font-size: var(--font__size-small);
  opacity: 0;
  padding: var(--spacing__large);
  position: absolute;
  right: var(--spacing__large);
  top: 100%;
  transition: all .2s ease-in-out;
  visibility: hidden;
  width: 180px;
  z-index: -1;
}

.list__actions--single {
  flex: 0;
}

.list--features {
  margin: 0 auto;
  max-width: 26rem;

  .ios & {
    max-width: 260px;
  }
}

.list--features > li:not(.list__item) {
  padding-left: 28px;
  position: relative;
}

.list--features li:before {
  border-bottom: 2px solid var(--color__mixlr-red); 
  border-right: 2px solid var(--color__mixlr-red); 
  content: '';
  height: 12px;
  left: .65rem;
  position: absolute;
  top: 3px;
  transform: rotate(35deg);
  width: 6px;
}

.list--icons {
  max-width: 34rem;
  margin: 0 auto;
}

.list--icons > li:not(.list__item) {
  padding-left: 56px;
  position: relative;
}

.list--icons svg {
  left: 0;
  position: absolute;
  top: 0;
}

.list--flex {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
}

.list__empty-view {
  align-items: center;
  color: var(--color__black-70);
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  max-width: 320px;
  justify-content: center;
  text-align: center;
  width: 100%;

  @media (prefers-color-scheme: dark) {
    .ios & {
      color: var(--color__white);
    }
  }
}

.list__empty-view--swiper {
  margin: 0;
  max-height: 800px;
  max-width: none;
  padding: var(--spacing__xlarge) 0;
  width: 100%;

  .ios &,
  .android & {
    background: none;
    border-radius: 0;
    max-height: initial;
    padding: 0;
  }
}

.list--app {
  background-color: var(--color__background);
  border-top: 1px solid var(--color__line-light);
  list-style: none;
  margin: 0 auto calc(var(--spacing__xlarge)*2);
  padding: 0;
  position: relative;
  width: 100%;

  > li {
    border-bottom: 1px solid var(--color__line-light);
    margin: 0;
    padding: 0;
    position: relative;
  }

  a {
    align-items: center;
    display: flex;
    color: var(--color__label);
    padding: var(--spacing__large) 0;
  }

  svg:first-child {
    fill: var(--color__label);
    margin-right: var(--spacing__large);
    top: -1px;
  }

  svg:last-child {
    fill: var(--color__mixlr-red);
    margin-left: auto;
    margin-right: -5px;
    top: -1px;
  }

  &:last-child {
    margin: 0;
  }
}

// Defination lists

dl {
  margin-bottom: var(--spacing__large);
}

dt,
dd {
  display: block;
}

dt {
  color: var(--color__black-40);
  font-size: var(--font__size-small);
}
