@import "scss/mixins";

// Player Wrapper =======================================

.player__controls {
  align-items: center;
  display: flex;
  gap: var(--spacing__large);
  margin-top: var(--spacing__xxlarge);
  opacity: 1;
  transition: opacity .2s ease-in-out;
  width: 100%;

  &--loading {
    opacity: 0;
  }

  @media screen and (max-height: 410px) {
    margin-top: 0;
  }

  :global(.embed) & {
    gap: var(--spacing);
  }
}

.player__title {
  color: var(--color__white);
  font-size: 1.6rem;
  font-weight: 900;
  margin: 0 0 var(--spacing__small);
  overflow: hidden;
  padding-right: var(--spacing);
  text-overflow: ellipsis;
  white-space: nowrap;

  :global {
    animation: fadeIn .5s;
  }
}

.player--full-screen {
  box-shadow: none;
  height: 100%;
}

.player__content {
  align-items: center;
  box-sizing: border-box;
  color: var(--color__label);
  padding: 0 var(--spacing__large);
  position: relative;
  transition: all .4s ease-in-out;
  width: 100%;
  z-index: 2;

  .player--full-screen & {
    background-color: rgba(0,0,0,0.6);
  }
}

.player__details {
  margin-right: var(--spacing__large);
  width: 28rem;
}

.player__meta {
  align-items: center;
  color: var(--color__black-40);
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;

  svg {
    fill: var(--color__black-40);
    height: 1.2rem;
    position: relative;
    top: -.1rem;
    width: 1.2rem;
  }

  > * {
    flex: 1;
  }

  :global {
    animation: fadeIn .5s;
  }
}

.player__meta__category {
  overflow: hidden;
  padding-right: var(--spacing);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.player__actions,
.player__tools {
  align-items: center;
  display: flex;
  gap: var(--spacing);
  list-style: none;
  justify-content: center;

  @include gap-fallback(var(--spacing));
}

.player__tools {
  margin: 0 0 0 auto;
}

// Volume Control =======================================

.volume {
  position: relative;
  z-index: 100;

  &:before {
    background-color: transparent;
    cursor: pointer;
    content: "";
    height: 64px;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 64px;
    z-index: -1;
  }
}

.volume__button {
  align-items: center;
  border-radius: 22px;
  background-color: rgba(var(--color__white-rgb), .2);
  backdrop-filter: blur(4px);
  border: 0;
  color: var(--color__white);
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  padding: 0;
  transition: all .2s ease-in-out;
  width: 36px;

  &:hover {
    background-color: rgba(var(--color__white-rgb), .3);
    color: var(--color__white);
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:disabled:hover {
    background-color: rgba(var(--color__white-rgb), .2);
  }

  :global(.mini-player.light-contrast) & {
    background-color: rgba(var(--color__black-rgb), .1);
    color: var(--color__black);

    &:hover {
      background-color: rgba(var(--color__black-rgb), .2);
    }
  }
}

.volume--path-mute {
  display: none;
}

.volume__button--mute {
  :global {
    .volume--path-mute {
      display: block;
    }
    .volume--path-low,
    .volume--path-mid,
    .volume--path-high {
      display: none;
    }
  }
}

.volume__button--low {
  :global {
    .volume--path-mute,
    .volume--path-mid,
    .volume--path-high {
      display: none;
    }
  }
}

.volume__button--mid {
  :global {
    .volume--path-mute,
    .volume--path-high {
      display: none;
    }
  }
}

.volume__button--high {
  :global {
    .volume--path-mute {
      display: none;
    }
  }
}

.volume__control {
  align-items: center;
  border-radius: var(--border__radius);
  display: none;
  height: 32px;
  left: 0;
  margin: 0 0 4px 2px;
  position: absolute;
  bottom: 0;
  padding: 00;
  transform: rotate(270deg) translateX(var(--spacing));
  transform-origin: 0 0;
  width: 160px;

  :global(.embed) & {
    margin: 0 0 -2px 2px;
    width: 60px;
  }
}

.volume:hover,
.volume:focus,
.volume:active {
  .volume__control {
    display: flex;
  }
}

.volume__button:disabled + .volume__control {
  display: none;
}

.volume__range {
  align-items: center;
  appearance: none;
  backdrop-filter: blur(4px);
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  height: 32px;
  position: relative;
  writing-mode: bt-lr;
  width: 100%;

  :global(.mini-player) & {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  }
}

.volume__range:disabled {
  background: var(--color__black-70);
  cursor: default;
  opacity: .5;
}

.volume__range:focus {
  box-shadow: none;
}

.volume__range::-webkit-slider-runnable-track {
  height: 4px;
  pointer-events: none;
}

.volume__range::-moz-range-track {
  height: 16px;
  pointer-events: none;
}

.volume__range::-ms-track {
  height: 16px;
  pointer-events: none;
}

.volume__range::-webkit-slider-thumb {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 16px;
  margin: -14px 0 0;
  height: 32px;
  width: 32px;
}

.volume__range:disabled::-webkit-slider-thumb {
  background: transparent;
}

.volume__range::-moz-range-thumb {
  appearance: none;
  background: transparent;
  border-radius: 8px;
  border: 0;
  height: 16px;
  width: 16px;
}

.volume__range:disabled::-moz-range-thumb {
  background: transparent;
}

.volume__range::-ms-thumb {
  appearance: none;
  background: transparent;
  border-radius: 8px;
  border: 0;
  height: 16px;
  margin: -6px 0 0 -1px;
  width: 16px;
}

.volume__range:disabled::-ms-thumb {
  background: transparent;
}

// Seekbar 

.seekbar__range {
  appearance: none;
  background-color: var(--color__black-70);
  backdrop-filter: blur(20px);
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  height: 8px;
  position: relative;
}

.seekbar__range:disabled {
  background: var(--color__black-70);
  cursor: default;
  opacity: .5;
}

.seekbar__range:focus {
  box-shadow: none;
}

.seekbar__range::-moz-range-track {
  height: 16px;
  pointer-events: none;
}

.seekbar__range::-ms-track {
  height: 16px;
  pointer-events: none;
}

.seekbar__range::-webkit-slider-thumb {
  appearance: none;
  background: var(--color__background);
  border-radius: 8px;
  box-shadow: 0 0 5px 2px rgba(0,0,0, 0.2);
  margin: 0 0 0 0;
  height: 16px;
  width: 16px;
}

.seekbar__range:disabled::-webkit-slider-thumb {
  background: var(--color__white);
}

.seekbar__range::-moz-range-thumb {
  appearance: none;
  background: var(--color__background);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: 0;
  height: 16px;
  width: 16px;
}

.seekbar__range:disabled::-moz-range-thumb {
  background: var(--color__white);
}

.seekbar__range::-ms-thumb {
  appearance: none;
  background: var(--color__background);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: 0;
  height: 16px;
  margin: -6px 0 0 0x;
  width: 16px;
}

.seekbar__range:disabled::-ms-thumb {
  background: var(--color__black-40);
}

.seekbar {
  margin-top: var(--spacing);
  position: relative;
  width: 100%;
}

.seekbar__time {
  display: flex;
  font-size: var(--font__size-mini);
  justify-content: space-between;
  margin-top: var(--spacing__small);

  :global(.embed) & {
    font-size: var(--font__size-tiny);
  }

  :global(.has-image) &,
  :global(.dark-contrast) & {
    color: var(--color__white);
  }
}

.seekbar__range {
  background: linear-gradient(
    to right,
    rgb(var(--color__primary)) 0%,
    rgb(var(--color__primary)) 0%,
    var(--color__white) 0%,
    var(--color__white) 100%
  );
  cursor: pointer;
  width: 100%;
}

// Mini player

.player-mini {
  align-items: center;
  background-color: var(--color__black);
  border-radius: 0;
  bottom: 0;
  color: var(--color__white);
  display: flex;
  gap: var(--spacing__large);
  height: 6.4rem;
  left: 0;
  max-width: 100%;
  position: fixed;
  right: 0;
  z-index: 1000;

  @include gap-fallback(var(--spacing__large));

  :global(.dark-mode) & {
    background-color: var(--color__black-90);
  }
}

.player-mini__details {
  align-items: center;
  display: flex;
  overflow: hidden;
  width: 100%;

  > div {
    width: 100%;
  }

  @include gap-fallback(var(--spacing__large));
}

.player-mini__meta {
  color: var(--color__white);
  display: flex;
  gap: var(--spacing);
  font-size: var(--font__size-small);

  @include gap-fallback(var(--spacing));

  :global(.light-contrast) & {
    color: var(--color__black);
  }
}

.player-mini__title {
  color: var(--color__white);
  font-size: var(--font__size-small);
  font-weight: 900;
  margin: 0;

  @media screen and (min-width: 460px) {
    font-size: var(--font__size-body);
  }

  :global(.light-contrast) & {
    color: var(--color__black);
  }
}

.player-mini__image {
  background-color: var(--color__background);
  border-radius: 50%;
  flex: none;
  height: 44px;
  position: relative;
  width: 44px;
}

.player-visualizer,
.player-visualizer__fallback {
  align-items: center;
  display: flex;
  gap: var(--spacing__small);
  height: 100px;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 420px;
  opacity: 0.3;
  overflow: hidden;
  width: 100%;

  @include gap-fallback(var(--spacing__small));

  > div {
    background-color: rgba(var(--color__white-rgb), .2);
    border-radius: 4px;
    display: inline-block;
    height: 100%;
    flex: 0 0 4px;
    transform: scaleY(0);
  }
}

.player-visualizer--mini,
.player-visualizer__fallback--mini {
  gap: var(--spacing__small);
  height: 20px;
  justify-content: center;
  opacity: 1;
  width: 20px;

  @include gap-fallback(var(--spacing__small));

  > div {
    background-color: currentColor;
    opacity: 1;
  }
}

.player-visualizer__fallback {
  height: 100px;
  justify-content: space-around;

  > div {
    animation: animate-bar 300ms linear infinite alternate;
    
    @for $i from 1 to 200 + 1 {
      &:nth-child(#{$i}) {
        animation-duration: (450 + random(600)) + ms;
        animation-delay: random(350) + ms;
      }
    }

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  &.player-visualizer__fallback--empty {
    > div {
      animation: none;
      height: 4px;
      transform: scaleY(1);
    }
  }

  :global(.embed) & {
    height: 40px;
  }
}

.player-visualizer__fallback--mini {
  height: 20px;
  margin: 0;
  justify-content: center;

  > div {
    animation: animate-bar-mini 300ms linear infinite alternate;
    transform: scaleY(1);

    @for $i from 1 to 3 {
      &:nth-child(#{$i}) {
        animation-duration: (450 + random(1000)) + ms;
        animation-delay: random(350) + ms;
      }
    }
  }
}

@keyframes animate-bar {
  0% {
    transform: scaleY(0.2);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes animate-bar-mini {
  50% {
    transform: scaleY(0.2);
  }
  100% {
    transform: scaleY(1);
  }
}
