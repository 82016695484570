// Channel stats =======================================

.channel-stats {
  display: flex;
  gap: var(--spacing__large);
  margin-bottom: var(--spacing__xlarge);

  > li {
    flex: 1;
  }
}

.channel-stats__number {
  display: block;
  font-size: var(--font__size-h2);
  font-weight: 750;
  lin-height: 1;
}