// User image styles ====================================

.profile-image {
  background: var(--light-background);
  border-radius: 50%;
  display: inline-block;
  height: 32px;
  width: 32px;

  > img, svg {
    border-radius: 50%;
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  svg {
    fill-opacity: .5;
  }
}

.profile-image--mini {
  height: 24px;
  width: 24px;
}

.profile-image--small {
  height: 36px;
  width: 36px;
}

.profile-image--medium {
  height: 64px;
  width: 64px;
}

.profile-image--large {
  height: 96px;
  width: 96px;
}

.profile-image--center {
  margin-right: auto;
  margin-left: auto;
}
