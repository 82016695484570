// Page switch animations

.page-opacity {
  background-color: var(--color__background);
  display: flex;
  flex-direction: column;
  flex: 1;
  left: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  @media (display-mode: standalone) {
    height: 100vh;
    padding-top: env(safe-area-inset-top, 0);
    padding-bottom: calc(var(--spacing__large) + env(safe-area-inset-bottom, 0));
  }
}

.page__full-screen {
  background-color: var(--color__background);
  bottom: 0;
  left: 0;
  height: 100%;
  position: fixed;
  overflow: hidden;
  right: 0;
  top: 0;
  z-index: 100;
}