.logo-fallback {
  height: 80% !important;
  width: 80% !important;

  :global(.light-contrast) & {
    filter: invert(100%);
  }
}

:global(.artwork-fallback) {
  fill: rgba(var(--color__primary), 1);
  fill-opacity: 1;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}