@import "scss/mixins";

.crowd {
  background-color: var(--color__background);
  border-radius: var(--border__radius);
  bottom: 0;
  left: 0;
  position: absolute;
  overflow: auto;
  top: 0;
  width: 100%;
  z-index: 30;

  &--visible {
    @media screen and (min-width: 740px) {
      border-right: 1px solid var(--color__line-light);
      border-radius: var(--border__radius) 0 0 var(--border__radius);
      max-width: 200px;
      z-index: -1;
    }

    @media screen and (min-width: 1024px) {
      position: relative;
      max-width: 160px;
      z-index: 30;
    }

    @media screen and (min-width: 1400px) {
      position: relative;
      max-width: 200px;
      z-index: 30;
    }
  }
}

.crowd__list {
  padding: var(--spacing__small) 0;
}

.crowd__member {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: var(--spacing);
  padding: var(--spacing__small) var(--spacing);

  @include gap-fallback(var(--spacing));
}

.crowd__member__title {
  color: var(--color__label);
  font-size: var(--font__size-mini);
  font-weight: 600;
  margin: 0;

  &:hover {
    opacity: 0.7;
  }
}

.crowd__empty {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}