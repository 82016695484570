// Support block ====================================

.support {
  background-color: var(--color__light-background);
  border-radius: var(--border__radius);
  margin-top: var(--spacing);
  padding: var(--spacing__large);
  text-align: center;
}

.support__text {
  font-weight: 900;
  margin: 0 0 var(--spacing) 0;
  position: relative;
  top: -.4rem;
}

.support__button:link,
.support__button:visited {
  background-color: rgba(0,0,0,.2);
  border-radius: calc(var(--border__radius) / 2);
  color: var(--color__white);
  display: block;
  font-weight: 900;
  padding: var(--spacing);
}