// Buttons =================================

.button {
  align-items: center;
  background: none;
  border: 0;
  border-radius: 66px;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: var(--font__size-body);
  font-weight: 600;
  height: var(--input-height);
  justify-content: center;
  margin: 0 auto;
  padding: 0 var(--spacing__xlarge);
  text-align: center;
  text-decoration: none;
  transition: all .2s ease-in-out;
  width: 100%;

  @media (prefers-reduced-motion) {
    transition: none;
  }

  > svg {
    margin: 0 var(--spacing) 0 0;
    vertical-align: middle;
  }

  &:disabled {
    background-color: var(--color__black-40);
  }
}

.button--inline {
  display: inline-flex;
  margin: 0;
  min-width: 140px;
  width: auto;
}

.button--tiny {
  font-size: var(--font__size-mini);
  height: calc(var(--input-height)/2);
  padding: 0 var(--spacing);
}

.button--mini {
  font-size: var(--font__size-small);
  height: calc(var(--input-height)/1.5);
}

.button--large {
  font-size: var(--font__size-h4);
  height: calc(var(--input-height)*1.2);
  padding: 0 var(--spacing__xlarge);
}

.button:focus {
  outline: none;
}

.button--primary,
a.button--primary:link,
a.button--primary:visited  {
  background-color: rgb(var(--color__primary));
  color: var(--color__white);
}

.button--primary:hover,
a.button--primary:hover  {
  background-color: rgba(var(--color__primary), .8);
}

.button--primary:focus {
  box-shadow: 0 0 0 3px rgba(var(--color__primary), .4);
}

.button--outline,
a.button--outline:link,
a.button--outline:visited  {
  background-color: transparent;
  border: 1px solid var(--color__line-light);
  color: var(--color__label);
}

.button--outline:hover,
a.button--outline:hover  {
  border: 1px solid var(--color__line-light);
}

.button--outline--light,
a.button--outline--light:link,
a.button--outline--light:visited  {
  background-color: transparent;
  border: 1px solid var(--color__line);
  color: var(--color__mixlr-red);
}

.button--outline--light:hover,
a.button--outline--light:hover  {
  border-color: rgba(var(--color__primary), .5);
}

.button--light,
a.button--light:link,
a.button--light:visited  {
  background-color: var(--color__light-background);
  color: var(--color__label);
}

.button--light:hover,
a.button--light:hover  {
  background-color: var(--color__light-background);
}

.button--default,
a.button--default:link,
a.button--default:visited  {
  background-color: var(--color__background);
  color: var(--color__label);
}

.button--default:hover,
a.button--default:hover  {
  background-color: var(--color__background);
}

.button--alert,
a.button--alert:link,
a.button--alert:visited  {
  background-color: var(--color__red);
  color: var(--color__white);
}

.button--alert:hover,
a.button--alert:hover  {
  background-color: var(--color__red);
}

.button--submit:disabled,
.button--loading:disabled {
  cursor: default;
}

.button__more {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: var(--spacing);

  > span {
    color: var(--color__mixlr-red);
  }
}

.button--link,
a.button--link:link,
a.button--link:visited {
  background-color: transparent;
  border: 0;
  color: var(--color__mixlr-red);
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  height: auto;
  margin: 0;
  max-width: initial;
  padding: 0;
  text-align: left;
  transition: all .2s ease-in-out;
  width: auto;

  > svg {
    margin: -2px var(--spacing__small) 0 0;
    vertical-align: middle;
  }

  &:hover {
    color: var(--color__black);
  }

  &.selected {
    color: var(--color__label);
  }

  .android & {
    line-height: 1.4;
  }
}

.button__group {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: var(--spacing__xxlarge) auto;

  > *:first-child {
    margin-right: var(--spacing__large);
  }
}

.button__group--filters {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-bottom: var(--spacing__large);
  white-space: nowrap;

  > button {
    border-radius: 0;
    margin-left: -1px;

    &:hover {
      position: relative;
      z-index: 100;
    }
  }

  > button:first-child {
    border-radius: var(--border__radius) 0 0 var(--border__radius);
  }

  > button:last-child {
    border-radius: 0 var(--border__radius) var(--border__radius) 0;
  }

  .selected {
    background-color: var(--color__light-background);
    color: var(--color__label);

    &:hover {
      border-color: var(--color__label);
    }
  }

  @media screen and (min-width: 640px) {
    margin-bottom: 0;
  }
}

.button--icon {
  border-radius: 50%;
  line-height: var(--input-height);
  padding: 0;
  width: 48px;

  > svg {
    margin: 0;
    pointer-events: none;
  }
}

.button--icon--transparent,
.button--icon--transparent:hover {
  background-color: transparent;
}

.button--icon--transparent:hover {
  color: var(--color__black);

  @media (prefers-color-scheme: dark) {
    .ios & {
      color: var(--color__white);
    }
  }
}

a.button--cancel--webview:link,
a.button--cancel--webview:visited {
  font-weight: 500;
  left: 0;
  padding: var(--spacing__large);
  position: fixed;
  top: 0;
}

.button__event {
  margin-bottom: var(--spacing__large);
  position: relative;

  > .button:first-child {
    text-align: left;
  }
}

.button__schedule {
  background-color: var(--color__white);
  border-radius: calc(var(--border__radius)/1.5);
  box-shadow: 0 0 8px rgba(#000000, .1);
  color: var(--color__black);
  padding: 0 var(--spacing);
  position: absolute;
  right: var(--spacing__large);
  top: 50%;
  transform: translateY(-50%);
  width: auto;

  &:link {
    color: var(--color__black);
  }
}

.button__copy {
  position: relative;

  &:after {
    background-color: var(--color__green--light);
    border-radius: var(--border__radius);
    color: var(--color__green);
    content: "Copied!";
    left: 50%;
    font-family: var(--font__family);
    font-size: 9px;
    line-height: initial;
    opacity: 0;
    padding: 6px 0;
    position: absolute;
    transition: all .2s ease-in-out;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    top: 50%;
    visibility: hidden;
    width: 42px;
  }

  &.copied:after {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    border: 0;
  }
}

.button--floating {
  bottom: calc(var(--spacing) + env(safe-area-inset-bottom));
  left: var(--spacing__large);
  margin: auto;
  max-width: 420px;
  padding: 0 ;
  position: fixed;
  right: var(--spacing__large);
  width: auto;
}

.button--reset {
  background: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  text-align: left;
}