.powered-by {
  padding: var(--spacing);
  text-align: center;
  opacity: 1;
  width: 100%;

  :global(.body-full-screen) & {
    position: relative;
  }

  &--non-live {
    background-color: rgba(var(--color__primary), 0.9);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 10000;
  }
}

.powered-by__logo {
  align-items: center;
  color: rgba(var(--color__label), 1);
  display: flex;
  font-size: var(--font__size-mini);
  height: auto;
  gap: var(--spacing);
  justify-content: center;

  svg {
    fill: rgba(var(--color__label), 1);
    width: 6rem;
  }

  :global(.body-full-screen) & {
    color: var(--color__white);

    svg {
      fill: var(--color__white);
    }

    :global(.light-contrast) & {
      color: var(--color__black);

      svg {
        fill: var(--color__black);
      }
    }
  }
}