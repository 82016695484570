.search-form {
  flex: 1;
  
  > form {
    position: relative;
  }

  input[type="search"] {
    background-color: var(--color__background);
    padding-left: 48px;

    :global(.ios-listener) &,
    :global(.android-listener) & {
      height: 40px;
    }
  }

  *::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  button[type="submit"] {
    left: var(--spacing__small);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    :global(.ios-listener) &,
    :global(.android-listener) & {
      height: 40px;
      width: 40px;
    }
  }

  button[type="button"] {
    font-size: 12px;
    gap: 4px;
    right: var(--spacing__large);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}