@import "scss/mixins";

// Channel stats =======================================

.channel-embed {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;

  &:global(.has-image) {
    &:after {
      background-color: rgba(var(--color__black-rgb), .5);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.channel-embed__image {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.channel-embed__content {
  padding: var(--spacing__large);
  position: relative;
  text-align: center;
  z-index: 1;

  :global(.has-image) & {
    color: var(--color__white);
    text-shadow: 0 0 15px rgba(var(--color__black-rgb), .9);
  }
}

.channel-embed__title {
  display: -webkit-box;
  font-size: var(--font__size-h4);
  margin: 0 0 var(--spacing__small);
  max-width: 50ch;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.channel-embed__links {
  align-items: center;
  list-style: none;
  display: flex;
  gap: var(--spacing__large);
  font-size: var(--font__size-small);
  justify-content: center;
  margin: 0 0 var(--spacing);
  padding: 0;

  @include gap-fallback(var(--spacing_large));

  a,
  button {
    color: var(--color__black);
    justify-content: center;

    :global(.has-image) & {
      color: var(--color__white);
    }

    &:hover {
      color: rgb(var(--color__primary));
    }
  }
}