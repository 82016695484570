@import "scss/mixins";

.button {
  align-items: center;
  background-color: rgb(var(--color__primary));
  border: 0;
  border-radius: 66px;
  cursor: pointer;
  color: var(--color__white);
  font-size: var(--font__size-body);
  font-weight: 600;
  height: 4.2rem;
  display: flex;
  justify-content: center;
  padding: 0 var(--spacing__large);
  transition: all .2s ease-in-out;
  width: 100%;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  :global(.light-contrast) & {
    color: var(--color__black);
  }

  :global(.ios) &,
  :global(.android) & {
    height: 42px;
  }

  &:disabled {
    cursor: default;
  }
}

.button--inline {
  display: inline-flex;
  width: auto;
}

.button:hover {
  background-color: rgba(var(--color__primary), 0.9);
  color: var(--color__white);

  :global(.light-contrast) & {
    color: var(--color__black);
  }
}

.button--large {
  font-size: 1.8rem;
  height: 4.8rem;

  :global(.ios) &,
  :global(.android) & {
    height: 48px;
  }
}

.button--small {
  font-size: var(--font__size-small);
  height: 3.6rem;

  :global(.ios) &,
  :global(.android) & {
    height: 36px;
  }
}

.button--mini {
  font-size: var(--font__size-mini);
  height: 2.2rem;

  :global(.ios) &,
  :global(.android) & {
    height: 22px;
  }
}

.button--icon {
  border-radius: 22px;
  background-color: rgb(var(--color__primary));
  align-items: center;
  border: 0;
  color: var(--color__white);
  cursor: pointer;
  display: inline-flex;
  height: 44px;
  flex: 0 0 44px;
  justify-content: center;
  padding: var(--spacing__small);
  position: relative;
  transition: all .2s ease-in-out;
  transform: scale(1);
  width: 44px;

  &:hover {
    background-color: rgba(var(--color__primary), 0.9);
    color: var(--color__white);
  }

  :global(.light-contrast) & {
    color: var(--color__black);

    &:hover {
      color: var(--color__black);
    }
  }

  :global(.embed) & {
    height: 32px;
    flex: 0 0 32px;
    width: 32px;

    > svg {
      height: 16px;
      width: 16px;
    }
  }
}

.button--icon-outline {
  background: var(--color__background);
  border: 1px solid var(--color__line);
  color: rgb(var(--color__primary));

  &:hover {
    background: var(--color__background);
    border-color: rgb(var(--color__primary));
    color: rgb(var(--color__primary));
  }
}

.button--icon-transparent {
  background: none;
  color: rgb(var(--color__primary));

  &:hover {
    background: none;
    color: var(--color__label);
  }

  :global(.light-contrast) & {
    color: rgb(var(--color__primary));

    &:hover {
      color: var(--color__label);
    }
  }

  :global(.dark-mode) & {
    color: var(--color__label);

    &:hover {
      color: rgb(var(--color__primary));
    }
  }
}

.button--icon--invert {
  background-color: rgba(var(--color__white-rgb), .15);
  color: var(--color__white);

  &:hover {
    background-color: rgb(var(--color__primary));
    color: var(--color__white);
  }

  :global(.light-contrast) & {
    color: var(--color__white);

    &:hover {
      color: var(--color__black);
    }
  }
}

.button--icon-highlight {
  background-color: rgba(var(--color__primary), 0.4);
  color: var(--color__label);
}

.button--icon-play {
  background-color: rgb(var(--color__primary));
  border-radius: 45px;
  height: 90px;
  width: 90px;

  svg {
    height: 36px;
    width: 36px;
  }

  &:hover {
    svg {
      fill: var(--color__white);
    }
  }
}

.button--icon-player {
  color: var(--color__white);
}

.button--icon-small {
  font-size: var(--font__size-small);
  height: 24px;
  flex: 0 0 24px;
  width: 24px;

  :global(.embed) & {
    height: 20px;
    flex: 0 0 20px;
    width: 20px;
  }
}

.button--icon:disabled {
  cursor: default;
}

.button--icon:disabled:hover {
  color: var(--color__white);
  opacity: 1;
  transform: scale(1);
}

.button--icon:not(.button--icon-text):disabled {
  opacity: 0.3;
}

.button--icon-loading:disabled {
  background-color: rgba(var(--color__primary), 0.5);
  color: rgb(var(--color__primary));
  opacity: 1 !important;
  overflow: hidden;
  transition: none;

  svg {
    animation: pop 0.3s linear 1;
    fill: rgb(var(--color__primary));
    position: relative;
    z-index: 1;
  }

  &:before {
    animation: fill 10s ease-in-out;
    background: var(--color__black);
    content:"";
    height: 100%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 100%;
  }
}

@keyframes fill {
  from {
    top: 100%;
    transform: translateX(-50%);
  }
  to {
    top: 0;
    transform: translateX(-50%);
  }
}

@keyframes pop {
  50% { 
    transform: scale(1.2);
  }
}

.button--icon-text {
  font-size: var(--font__size-body);
  font-weight: 600;
  padding: 0 var(--spacing__large);
  width: auto;

  svg {
    margin-right: var(--spacing);
  }

  &:hover {
    transform: scale(1);
  }

  :global(.embed) & {
    font-size: var(--font__size-small);
    width: auto;
  }
}

.button--icon-mini {
  font-size: var(--font__size-mini);
  height: 2.2rem;
  padding: 0 10px;

  svg {
    height: 12px;
    stroke: currentColor;
    stroke-width: 0.5px;
    width: 12px;
  }

  :global(.ios) &,
  :global(.android) & {
    height: 22px;
  }
}

.button--icon-notification {
  position: relative;

  &:after {
    background-color: var(--color__white);
    border: 3px solid rgb(var(--color__primary));
    border-radius: 6px;
    content: "";
    height: 12px;
    position: absolute;
    right: var(--spacing);
    top: var(--spacing);
    transition: all .2s ease-in-out;
    width: 12px;
    z-index: 1;
  }

  &:hover:after {
    border-color: rgba(var(--color__primary), 1);
  }

  :global(.light-contrast) & {
    &:after {
      background-color: var(--color__label);
    }
  }

  &.button--icon-transparent {
    &:after {
      background-color: rgb(var(--color__primary));
      border-color: var(--color__white);
    }
  }
}

.button--close {
  background-color: transparent;
  height: auto;
  flex: none;
  padding: 0;
  width: auto;

  svg {
    fill: rgb(var(--color__primary));
  }

  &:hover {
    background: none;

    svg {
      fill: var(--color__label);
    }
  }
}

.button--minimise {
  position: fixed;
  top: var(--spacing__large);
  right: var(--spacing__large);
}

.button--link {
  align-items: center;
  background: none;
  border: 0;
  color: rgb(var(--color__primary));
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  gap: var(--spacing__small);
  margin: 0;
  padding: 0;
  transition: all .2s ease-in-out;

  &:hover {
    color: var(--color__label);
  }

  &-inline {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  @include gap-fallback(var(--spacing__small));
}

// Submit buttons

.button--loader {
  align-items: center;
  background: none;
  border: 0;
  display: inline-flex;
  font-weight: normal;
  gap: var(--spacing__small);
  margin: var(--spacing__large) auto 0;
  justify-content: center;
  padding: 0;
  text-decoration: none;

  &:hover {
    background: none;
  }

  svg {
    height: 14px;
    width: 14px;
  }
}

// Links to external sites

.external-link {
  align-items: center;
  display: flex;

  &:hover {
    color: var(--color__black);
  }
}

.external-link--text {
  flex: 1;
  gap: var(--spacing);
  min-width: 0;

  @include gap-fallback(var(--spacing));

  svg {
    flex: none;
  }
}

// Tooltips on buttons

.button__tooltip {
  background-color: var(--color__black);
  border-radius: var(--spacing__small);
  color: var(--color__white);
  font-size: var(--font__size-mini);
  left: 100%;
  margin-left: -4px;
  opacity: 0;
  padding: 1px var(--spacing__small);
  position: absolute;
  transform: translateY(-50%);
  transition: all .2s ease-in-out;
  top: 50%;
  visibility: hidden;

  &:before {
    content: "";
    height: 0;
    border-top: 2px solid transparent;
    border-right: 4px solid var(--color__black);
    border-bottom: 2px solid transparent;
    position: absolute;
    margin-top: -2px;
    top: 50%;
    right: 100%;
    width: 0;
  }

  .button--icon:hover & {
    opacity: 1;
    visibility: visible;
  }
}
