summary {
  cursor: pointer;
  list-style-type: none;
  outline: none;
}

summary::-webkit-details-marker {
  display: none;
}

summary::marker {
  display: none;
}

.user-menu {
  margin-left: var(--spacing__large);
  position: relative;
  text-align: center;
  z-index: 100;

  > summary {
    height: 24px;
  }
}

.user-menu:not([open]) {
  > .dropdown {
    display: none;
  }
  summary > .image {
    box-shadow: none;
    transition: all .1s ease-in-out;
  }
}

.user-menu[open] {
  > .dropdown {
    display: block;
  }
  summary > .image {
    box-shadow: 0 0 0 3px RGBA(var(--color__mixlr-red-rgb), .1);
    transition: all .1s ease-in-out;
  }
}

.user-menu {
  outline: none;
}

.user-menu__dropdown {
  background-color: var(--color__background);
  border-radius: var(--border__radius--mid);
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin-top: var(--spacing);
  position: absolute;
  right: 0;
  top: 100%;
  width: 280px;
  z-index: 100;

  :global(.dark-mode) & {
    background-color: var(--color__black-90);
  }
}

.user-menu__details {
  border-bottom: 1px solid var(--color__line-light);
  font-family: var(--font__family);
  padding: var(--spacing__xlarge);
  text-align: center;

  h4 {
    font-family: var(--font__family);
    font-weight: 750;
    margin: var(--spacing) 0 var(--spacing__small);
  }

  ul {
    font-size: var(--font__size-small);
    font-weight: 500;
  }
}

.user-menu__logout {
  background: none;
  border: 0;
  color: var(--color__label);
  cursor: pointer;
  font-size: var(--font__size-small);
  display: block;
  height: var(--input-height);
  line-height: var(--input-height);
  padding: 0 var(--spacing);
  text-align: center;
  width: 100%;

  &:hover {
    color: rgb(var(--color__primary));
  }
}
