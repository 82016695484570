// Privacy window ================================

.privacy__header {
  align-items: center;
  display: flex;
  margin-top: var(--spacing);
  justify-content: center;
  text-align: center;

  :global(.embed) & {
    margin-bottom: var(--spacing__small);
    font-size: var(--font__size-body);
  }
}

.privacy__header svg {
  margin-right: var(--spacing);
  
  :global(.embed) & {
    height: 12px;
    width: 12px;
  }
}

.privacy__link {
  font-size: var(--font__size-h4);

  :global(.embed) & {
    font-size: var(--font__size-small);
  }
}

// Privacy bar =================================

.privacy-notice {
  align-items: center;
  background-color: rgb(var(--color__primary));
  box-shadow: 0 0 8px rgba(var(--color__black-rgb), .3);
  border-radius: 50%;
  color: var(--color__white);
  display: flex;
  height: 24px;
  left: var(--spacing);
  justify-content: center;
  position: absolute;
  top: var(--spacing);
  width: 24px;
  z-index: 100;

  span {
    display: none;
    font-size: var(--font__size-mini);
    margin-left: var(--spacing__small);
  }

  :global(.light-contrast) & {
    color: var(--color__black);
  }

  :global(.embed) & {
    height: 16px;
    width: 16px;

    svg {
      height: 8px;
      width: 8px;
    }
  }
}

.privacy-notice--native {
  border-radius: 12px;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  padding: var(--spacing__small) var(--spacing);
  top: calc(var(--spacing) + var(--sat));
  width: auto;

  span {
    display: block;
  }

  svg {
    position: relative;
    top: -1px;
  }

  :global(.creator-da) &,
  :global(.ios-listener) &,
  :global(.android-listener) & {
    margin-top: 40px;
  }

  :global(.android-creator) & {
    margin-top: 55px;
  }
}
