@import "./scss/reset";
@import "./scss/variables";
@import "./scss/grid";
@import "./scss/lists";
@import "./scss/links";
@import "./scss/typography";
@import "./scss/buttons";
@import "./scss/forms";
@import "./scss/utilities";
@import "./scss/clipboard";
@import "./scss/mixins";
@import "./scss/vendor";

// Base layout styles ===================================

* {
  box-sizing: border-box;
}

html {
  background-color: var(--color__background);
  font-size: 62.5%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.creator-da {
    overflow: hidden;
  }

  @media (display-mode: standalone) {
    height: 100vh;
  }
}

body {
  background-color: var(--color__background);
  color: var(--color__label);
  font-family: var(--font__family);
  font-display: swap;
  font-size: var(--font__size-body);
  font-weight: 500;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;

  &.body-full-screen {
    overflow: hidden;
  }
}

.app__main {
  flex: 1;

  &--non-live {
    padding-bottom: 70px;
  }
}

.container--wide {
  background-color: var(--color__background);
  margin: 0 auto;
  max-width: 96rem;
  padding: 0 calc(var(--spacing__large) + env(safe-area-inset-right)) calc(var(--spacing__large) + env(safe-area-inset-bottom)) calc(var(--spacing__large) + env(safe-area-inset-left));
  width: 100%;

  @media screen and (min-width: 960px) {
    padding: 0 calc(var(--spacing__xlarge) + env(safe-area-inset-right)) calc(var(--spacing__xlarge) + env(safe-area-inset-bottom)) calc(var(--spacing__xlarge) + env(safe-area-inset-left));
  }

  .ios-listener &,
  .android-listener & {
    padding-top: var(--spacing__large);
    padding-bottom: var(--spacing__large);
  }

  @media (display-mode: standalone) {
    padding-top: var(--spacing__large);
    padding-bottom: var(--spacing__large);
  }
}

// Accessibility helpers ================================

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// Third party CSS

.grecaptcha-badge {
  visibility: hidden;
}
