@import "scss/mixins";

.search {
  @include bluredBackground();
  display: flex;
  flex-direction: column;
  height: 100%;
  outline: none;
  overflow: auto;
  overscroll-behavior-y: contain;
  padding: var(--spacing);
  position: relative;
  width: 100%;
  z-index: 1000;

  @media (display-mode: standalone) {
    height: 100vh;
  }

  @media screen and (min-width: 720px) {
    padding: var(--spacing__xlarge);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 auto;
    max-width: 96rem;
    width: 100%;
  }

  &__overlay {
    bottom: 0;
    left: 0;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999999;

    @media (display-mode: standalone) {
      // padding-top: calc(var(--spacing) + env(safe-area-inset-bottom, 0));
      // padding-bottom: calc(var(--spacing) + env(safe-area-inset-bottom, 0));
      padding-top: env(safe-area-inset-bottom, 0);
      padding-bottom: env(safe-area-inset-bottom, 0);
    }
  }
}