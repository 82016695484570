.access {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  > :global(.has-image):after,
  > :global(.has-image):before {
    display: none;
  }

  >div {
    padding: var(--spacing__large);
  }

  :global(.embed) & {
    font-size: var(--font__size-small);

    p {
      margin-bottom: var(--spacing__small);
    }

    >div {
      padding: var(--spacing);
    }

    > :global(.has-image):after,
    > :global(.has-image):before {
      display: none;
    }
  }
}

.access-modal {
  align-items: center;
  box-shadow: 0 0 20px rgba(var(--color__black-rgb), .3);
  background-color: var(--color__background);
  border-radius: var(--border__radius);
  align-self: center;
  color: var(--color__label);
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 400px;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 10;

  .event-date {
    justify-content: center;
    margin: var(--spacing) 0;
  }

  :global(.embed) & {
    max-width: 260px;
  }

  &--wide {
    max-width: 640px;
  }
}

.access-modal__content {
  padding: var(--spacing__large);
}