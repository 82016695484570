.GifPickerReact {
  border: 0;
  padding-bottom: var(--spacing);

  .gpr-header{
    border: 0;
    padding: var(--spacing);
  }

  .gpr-category-list {
    grid-template-columns: repeat(2, 1fr) !important;
    padding: var(--spacing);
    padding-top: 0;
  }

  input.gpr-search {
    background: var(--color__background) !important;
    border: 1px solid var(--color__line-light) !important;
    border-radius: 4px !important;
  }
  
  .gpr-gif-list {
    padding: var(--spacing);
    padding-top: 0;
  }

  .gpr-gif-list-column {
    grid-template-columns: repeat(2, 1fr) !important;
    padding-bottom: var(--spacing);
    width: 50%;
  }

  .gpr-result-image {
    box-shadow: none;
    border: 0;
    transition: none;

    &:hover {
      box-shadow: none;
    }
  }
}