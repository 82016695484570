// Typography ====================================

h1,h2,h3,h4,h5,legend {
  font-family: var(--font__family);
  font-weight: 700;
  line-height: 1.3;
  margin: 0 0 var(--layout__margin);
}

h1,
.h1 {
  font-size: var(--font__size-h2);

  @media screen and (min-width: 720px) {
    font-size: var(--font__size-h1);
  }
}

h2,
.h2 {
  font-size: var(--font__size-h3);

  @media screen and (min-width: 720px) {
    font-size: var(--font__size-h2);
  }
}

h3,
.h3 {
  font-size: var(--font__size-h4);

  @media screen and (min-width: 720px) {
    font-size: var(--font__size-h3);
  }
}

h4,
h5,
.h4,
.h5,
legend {
  font-size: var(--font__size-h4);
}

p {
  margin: 0 0 var(--layout__margin);
}

.small {
  font-size: var(--font__size-small);
  margin: 0;
}

.uppercase {
  text-transform: uppercase;
}

.intro {
  font-size: var(--font__size-h4);
}

.textarea-format {
  white-space: pre-wrap;
  width: 100%;
}

strong {
  font-family: var(--font__family);
  font-weight: 600;

  + p {
    margin-top: var(--spacing__small);
  }
}

.header--thin {
  font-weight: 500;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate-multiline {
  display: -webkit-box;
  max-width: 50ch;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.tnum {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}
