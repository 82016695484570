.pricing-table {
  display: flex;
  gap: var(--spacing__large);
  list-style: none;
  justify-content: center;
  margin: 0 0 var(--spacing__large);
  padding: 0;
  width: 100%;
}

.pricing-table__item {
  border-radius: var(--border__radius--mid);
  border: 1px solid var(--color__line);
  flex: 1;
  max-width: 300px;
}

.pricing-table dl {
  margin: 0;
}

.pricing-table dt {
  background-color: rgba(var(--color__primary), 0.3);
  border-bottom: 1px solid var(--color__line);
  color: var(--color__black);
  font-weight: bold;
  padding: var(--spacing);
  text-transform: capitalize;
}

.pricing-table dd {
  padding: var(--spacing);
}

.pricing-table__features {
  list-style: none;
  margin: var(--spacing) auto var(--spacing__large);
  padding: 0;
  text-align: left;
  width: 70%;
}

.pricing-table__features li {
  margin-bottom: var(--spacing);
}

.pricing-table__features svg {
  margin-right: var(--spacing__small);
  position: relative;
  vertical-align: middle;
  top: -1px;
}

.pricing-table__amount {
  display: block;
  font-size: var(--font__size-mega);
  font-weight: 900;
  line-height: 1;

  + small {
    font-weight: bold;
    text-transform: uppercase;
  }
}